.tiktokvideo {
    border: 1px solid rgb(225, 217, 217);
    border-radius: 8px;
    display: flex;
    margin-bottom: 10px;
    position: relative;
  }

  .tiktokvideo__declined::after {
    content: "TikTok Declinado";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(120deg, #e1e1e0, #bfc5c5);
    opacity: .7;
    vertical-align: -50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
  }

  .tiktokvideo__img {
    height: 120px;
    width: 70px;
    border-radius: 6px;
    cursor: pointer;
  }

  .tiktokvideo__data {
    margin-left: 15px;
    display: flex;
    padding: 5px;
    flex-direction: column;
    justify-content: space-between;
  }

  .tiktokvideo__data__client__name {
    font-weight: bold;
  }

  .tiktokvideo__data__client__order {
    font-size: 12px;
  }

  .tiktokvideo__data__client__date {
    font-size: 12px;
  }

  .tiktokvideo__actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding-right: 10px;
  }

  .tiktokvideo__actions button {
    min-width: 100px;
  }

  .tiktokvideo__modal {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
  }

  .tiktokvideo__modal__iframe__wrapper {
    left: 0;
    width: 100%;
    position: relative;
  }

  .tiktokvideo__modal__iframe {
    width: 325px;
    height: 563px;
  }

  .tiktokvideo__modal__actions {
    padding: 20px 0 0 0;
    display: flex;
    justify-content: flex-end;
  }

  .tiktokvideo__modal__actions button:first-child {
    margin-right: 10px;
  }

  @media only screen and (min-width: 768px) {
    .tiktokvideo__actions {
      margin-left: auto;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      padding-bottom: 10px;
    }
    .tiktokvideo__actions button:first-child {
      margin-right: 10px;
    }
  }