@import url('https://use.fontawesome.com/releases/v5.4.1/css/all.css');

ul.ks-cboxtags {
  list-style: none;
  padding: 0px;
}

ul.ks-cboxtags li {
  display: inline;
  margin-right: 5px;
}

ul.ks-cboxtags li label {
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid rgba(139, 139, 139, 0.3);
  color: #adadad;
  border-radius: 25px;
  white-space: nowrap;
  margin: 3px 0px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transition: all 0.2s;
}

ul.ks-cboxtags li label {
  padding: 8px 12px;
  cursor: pointer;
}

ul.ks-cboxtags li label::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 12px;
  padding: 2px 6px 2px 2px;
  content: '\f067';
  transition: transform 0.3s ease-in-out;
}

ul.ks-cboxtags li input[type='checkbox']:checked + label::before {
  content: '\f00c';
  transform: rotate(-360deg);
  transition: transform 0.3s ease-in-out;
}

ul.ks-cboxtags li input[type='checkbox']:checked + label {
  border: 1px solid #38a169;
  background-color: gray;
  color: #fff;
  transition: all 0.2s;
}

ul.ks-cboxtags li input[type='checkbox'] {
  display: absolute;
}

ul.ks-cboxtags li input[type='checkbox'] {
  position: absolute;
  opacity: 0;
}

ul.ks-cboxtags li input[type='checkbox']:focus + label {
  border: 1px solid #38a169;
}