.iphone-frame {
  position: relative;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
    rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
    rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.scroll-container {
  scroll-snap-type: y mandatory;
  height: calc(100% - 60px); /* Reserve space for the footer */
}

.video-box {
  display: flex;
  align-items: center;
  scroll-snap-align: start;
  position: relative;
  height: 100%;
  width: 100%;
}

.video-box video {
  box-sizing: border-box;
  padding: 8px 8px 0px 8px;
  margin: 0;
  max-height: 100%;
  max-width: 100%;
}

.footer-menu {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  background-color: rgba(0, 0, 0, 0.8);
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scroll-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll; /* Add this to enable scrolling when necessary */
}
