.ql-toolbar {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.ql-container {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  min-height: 100px;
  border: none;
}

.ql-container.ql-snow {
  border: none;
}

.ql-toolbar.ql-snow {
  border: none;
  border-bottom: 1px solid #e2e8f0;
  box-sizing: border-box;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  padding: 8px;
}

.quill > .ql-container > .ql-editor.ql-blank::before{
  font-size: 14px;
  color: #718096;
}