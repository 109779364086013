.react-select-cruwi {
  border: none;
}

.react-select-cruwi > div{
  border: 1px solid #e2e8f0;
  border-radius: 6px;
}

progress::-webkit-progress-bar {background-color: rgb(230, 224, 224); width: 100%; border-radius: 8px;}
progress {background-color: rgb(230, 224, 224); border-radius: 8px;}
progress::-moz-progress-bar { background: blue; border-radius: 8px;}
progress::-webkit-progress-value { background: blue; border-radius: 8px;}
progress { color: blue; border-radius: 8px;}

.stepper_custom_indicator {
  border: 2px solid black !important;
}

.stepper_custom_separator {
  background: black !important;
}

.stepper_custom_indicator[data-status="complete"] {
  background: #000 !important;
}

.stepper_custom_indicator[data-status="incomplete"] {
  background: #fff !important;
}


.stepper_custom_indicator[data-status="active"] {
  background: #fff !important;
}